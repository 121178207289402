import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.6_@opentelemetry+api@1.6.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.6_@opentelemetry+api@1.6.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.6_@opentelemetry+api@1.6.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.6_@opentelemetry+api@1.6.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.6_@opentelemetry+api@1.6.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.6_@opentelemetry+api@1.6.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/components/render-from-template-context.js");
